import * as THREE from 'three'
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry'
import { degToRad } from "./utils";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export class Computer {
    pcGrp: THREE.Group;
    private startTime:any;
    private currentTime:any;
    private width = 2.5;
    private monitorWidth = 2.4;
    private screenWidth = 0.55;
    pcGeometry: RoundedBoxGeometry;
    pcMaterial: THREE.MeshBasicMaterial;
    pcMesh: THREE.Mesh;
    monitorGeometry: RoundedBoxGeometry;
    monitorMaterial: THREE.MeshBasicMaterial;
    monitorMesh: THREE.Mesh;
    screenGeometry: RoundedBoxGeometry;
    screenMaterial: THREE.MeshBasicMaterial;
    screenMesh: THREE.Mesh;
    screenTabsGeometry: RoundedBoxGeometry;
    screenTabsMaterial: THREE.MeshBasicMaterial;
    screenTabsInactiveMaterial: THREE.MeshBasicMaterial;
    screenTab1Mesh: THREE.Mesh;
    screenTab2Mesh: THREE.Mesh;
    screenTab3Mesh: THREE.Mesh;
    screenTab4Mesh: THREE.Mesh;
    pcStandGeometry: RoundedBoxGeometry;
    pcStandMaterial: THREE.MeshBasicMaterial;
    pcStandHorizontalMesh: THREE.Mesh;
    pcStandVerticalMesh: THREE.Mesh;
    keyboardGeometry: RoundedBoxGeometry;
    keyboardMaterial: THREE.MeshBasicMaterial;
    keyboardMesh: THREE.Mesh;
    mouseGeometry: RoundedBoxGeometry;
    mouseMaterial: THREE.MeshBasicMaterial;
    mouseMesh: THREE.Mesh;
    codingLinesGeometry: RoundedBoxGeometry;
    codingLinesMaterial: THREE.MeshBasicMaterial;
    codingLineColorArr: string[]
    codingLineColor: string
    codingLinesArr: any[]
    codingLinesMesh: THREE.Mesh;
    tabsNeeded: boolean;
    isCoding: boolean;
    constructor(tabsNeededInfo: boolean, isCodingInfo: boolean) {
        this.tabsNeeded = tabsNeededInfo
        this.isCoding = isCodingInfo
        this.pcGrp = new THREE.Group()

        this.pcGeometry = new RoundedBoxGeometry(this.width, 1.2, 0.1, 20, 0.2)
        this.pcMaterial = new THREE.MeshBasicMaterial({ color: '#8a9c9d' })
        this.pcMesh = new THREE.Mesh(this.pcGeometry, this.pcMaterial)
        this.pcMesh.position.set(0, this.width, 0)
        this.pcMesh.castShadow = true
        this.pcMesh.receiveShadow = false; //default
        this.monitorGeometry = new RoundedBoxGeometry(this.monitorWidth, 1.1, 0.01, 20, 0.2)
        this.monitorMaterial = new THREE.MeshBasicMaterial({ color: '#36393E' })
        if(tabsNeededInfo===false && isCodingInfo ===false){
            this.monitorMaterial = new THREE.MeshBasicMaterial({color:'#ffffff'})
        }
        this.monitorMesh = new THREE.Mesh(this.monitorGeometry, this.monitorMaterial)
        this.monitorMesh.position.set(0, this.width, 0.05)
        this.monitorMesh.receiveShadow = false; //default
        if (this.tabsNeeded) {
            this.screenGeometry = new RoundedBoxGeometry(this.screenWidth, 1.1, 0.01, 20, 0.2)
            this.screenMaterial = new THREE.MeshBasicMaterial({ color: '#000000' })
            this.screenMesh = new THREE.Mesh(this.screenGeometry, this.screenMaterial)
            this.screenMesh.position.set(-0.925, this.width, 0.051)

            this.screenTabsGeometry = new RoundedBoxGeometry(0.2, 0.18, 0.01, 20, 0.2)
            this.screenTabsMaterial = new THREE.MeshBasicMaterial({ color: '#fbb52e' })
            this.screenTabsInactiveMaterial = new THREE.MeshBasicMaterial({ color: '#808080' })
            this.screenTab1Mesh = new THREE.Mesh(this.screenTabsGeometry, this.screenTabsMaterial)
            this.screenTab2Mesh = new THREE.Mesh(this.screenTabsGeometry, this.screenTabsInactiveMaterial)
            this.screenTab3Mesh = new THREE.Mesh(this.screenTabsGeometry, this.screenTabsInactiveMaterial)
            this.screenTab4Mesh = new THREE.Mesh(this.screenTabsGeometry, this.screenTabsInactiveMaterial)
            this.screenTab1Mesh.position.set(-0.92, 2.85, 0.056)
            this.screenTab2Mesh.position.set(-0.92, 2.62, 0.056)
            this.screenTab3Mesh.position.set(-0.92, 2.40, 0.056)
            this.screenTab4Mesh.position.set(-0.92, 2.18, 0.056)
        }

        this.pcStandGeometry = new RoundedBoxGeometry(0.75, 0.5, 0.05, 7, 0.2)
        this.pcStandHorizontalMesh = new THREE.Mesh(this.pcStandGeometry, this.pcMaterial)
        this.pcStandVerticalMesh = new THREE.Mesh(this.pcStandGeometry, this.pcMaterial)
        this.pcStandHorizontalMesh.position.set(0, 1.62, -0.05)
        this.pcStandHorizontalMesh.rotateX(degToRad(90))
        this.pcStandVerticalMesh.position.set(0, 1.75, -0.15)
        this.pcStandVerticalMesh.rotateX(Math.PI * 0.25)

        if (this.isCoding) {
            this.codingLineColorArr = ['#808080', '#44929f', '#6335ab']
            let lineDistance = 0.05
            this.codingLinesArr = []
            for (let i = 0; i < 20; i++) {
            const codingLineWidth =Math.random() + 0.5
            this.codingLinesGeometry = new RoundedBoxGeometry(codingLineWidth, 0.025, 0.01, 7, 0.02)
                const arrIndex = Math.floor(Math.random() * this.codingLineColorArr.length)
                this.codingLineColor = this.codingLineColorArr[arrIndex]
                this.codingLinesMaterial = new THREE.MeshBasicMaterial({ color: this.codingLineColor })
                this.codingLinesMesh = new THREE.Mesh(this.codingLinesGeometry, this.codingLinesMaterial)
                const codingLineYOffset = 3 - (lineDistance + (lineDistance * i))
                if(this.tabsNeeded){
                    this.codingLinesMesh.position.set(-(this.monitorWidth/2.1) + (codingLineWidth/2) + (this.screenWidth), codingLineYOffset, 0.051)
                }else{
                    this.codingLinesMesh.position.set(-(this.monitorWidth/2.1) + (codingLineWidth/2), codingLineYOffset, 0.051)
                }
                this.codingLinesArr.push(this.codingLinesMesh)
            }
            this.startTime = Date.now()
            this.codingLinesArr.forEach((codingLine)=>{
                this.pcGrp.add(codingLine)
            })
        }

        this.pcGrp.add(this.pcMesh, this.monitorMesh, this.screenMesh, this.screenTab1Mesh, this.screenTab2Mesh, this.screenTab3Mesh, this.screenTab4Mesh,  this.pcStandVerticalMesh, this.pcStandHorizontalMesh)
    }
} 