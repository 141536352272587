import * as THREE from "three";

type hexType = string // '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F';
type ColorType = `#${hexType}${hexType}${hexType}${hexType}${hexType}${hexType}`;

export class Pencil {
    pencilGrp: THREE.Group;
    pencilGeometry: THREE.CylinderGeometry;
    pencilMaterial: THREE.MeshStandardMaterial;
    pencilMesh: THREE.Mesh;
    pencilHeadGeometry: THREE.CylinderGeometry;
    pencilHeadMaterial: THREE.MeshStandardMaterial;
    pencilHeadMesh: THREE.Mesh;
    pencilTipGeometry: THREE.ConeGeometry;
    pencilTipMaterial: THREE.MeshStandardMaterial;
    pencilTipMesh: THREE.Mesh;

    constructor(height: number, radius: number, color: ColorType = '#000000') {
        this.pencilGrp = new THREE.Group()
        this.pencilGeometry = new THREE.CylinderGeometry(radius, radius, height, 32, 1)
        this.pencilMaterial = new THREE.MeshStandardMaterial({ color: color })
        this.pencilMesh = new THREE.Mesh(this.pencilGeometry, this.pencilMaterial)
        this.pencilHeadGeometry = new THREE.CylinderGeometry(0.05, this.pencilMesh.geometry.parameters.radiusTop, 0.5);
        this.pencilHeadMaterial = new THREE.MeshStandardMaterial({ color: '#d4bfb0' })
        this.pencilHeadMesh = new THREE.Mesh(this.pencilHeadGeometry, this.pencilHeadMaterial)
        this.pencilHeadMesh.position.set(0, (this.pencilMesh.geometry.parameters.height / 2) + (this.pencilHeadMesh.geometry.parameters.height / 2), 0)
        this.pencilTipGeometry = new THREE.ConeGeometry(0.125, 0.3, 32);
        this.pencilTipMaterial = new THREE.MeshStandardMaterial({ color: color })
        this.pencilTipMesh = new THREE.Mesh(this.pencilTipGeometry, this.pencilTipMaterial)
        this.pencilTipMesh.position.set(0, (this.pencilMesh.geometry.parameters.height / 2) + (this.pencilHeadMesh.geometry.parameters.height), 0)
        this.pencilGrp.add(this.pencilMesh, this.pencilHeadMesh, this.pencilTipMesh)
    }
}